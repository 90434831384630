<template>
  <div>
    <v-card-actions>
      <v-row>
        <v-col>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn fab outlined color="primary" v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiMenu }}</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showTranfRoom()">
                <v-list-item-title>Transferir Quarto</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showEditCheckout()">
                <v-list-item-title>Editar Diárias</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showExtraDialog()">
                <v-list-item-title>Tarifas Extraordinárias</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showDialogConsumption()">
                <v-list-item-title>Consumo Hóspede</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showDialogObs()">
                <v-list-item-title>Adcionar Observação</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showdialogChangeLicensePlate()">
                <v-list-item-title>Alterar Placa Veículo</v-list-item-title>
              </v-list-item>
              <v-list-item @click="showCancelDialog()">
                <v-list-item-title class="custom-title">Cancelar CheckIn</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <!-- <v-btn x-large color="secondary" @click="showTranfRoom()" class="mr-4">Transferir Quarto</v-btn>
                <v-btn x-large color="secondary" @click="showEditCheckout()" class="mr-4">Checkout</v-btn> -->
        </v-col>
        <v-col class="text-right">
          <v-btn x-large color="orange" class="white--text" @click="showOutstanding()">Salvar conta avulsa</v-btn>
          <v-btn x-large color="warning" @click="partialPayment()" class="ml-2">adiantamento</v-btn>
          <v-btn x-large color="success" @click="closeAccount()" class="ml-2">Encerrar Conta</v-btn>
        </v-col>
        <!-- <v-col class="text-right">
                <v-btn class="mr-4" x-large text @click="dialogRoom=false">Fechar</v-btn>
                <v-btn color="primary" x-large :loading="editedItemLoading" @click="save()">Salvar</v-btn>
            </v-col> -->
      </v-row>
    </v-card-actions>
    <v-card-text class="mt-4">
      <v-row>
        <v-col cols="8">

          <v-tabs v-model="tab" background-color="secondary" outlined>
            <v-tab>Produtos</v-tab>
            <v-tab>Serviços</v-tab>
            <v-tab>Hóspedes</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <!-- TABELA PRODUTOS  -->
            <v-tab-item>
              <v-card outlined>
                <v-data-table :headers="headers" disable-sort :items="itemsProducts" sort-by="calories"
                  class="elevation-0" :footer-props="{
                    itemsPerPageText: 'Itens por pagina',
                    itemsPerPageOptions: [5],
                    showFirstLastPage: true,
                  }">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Produtos</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <!-- <v-spacer></v-spacer> -->
                      <autocomplete-search-product v-model="product" :dense="true" class="mt-7" />
                      <v-btn class="ml-2" color="primary" :disabled="!product" @click="dialogQtyProduct()">
                        <v-icon>{{ icons.mdiPlus }}</v-icon>Adicionar
                      </v-btn>
                      <v-btn color="secondary" :loading="loadingPrintProduct" :disabled="loadingPrintProduct"
                        @click="sendPrintProduct()" class="ml-2">Imprimir Pedidos</v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn icon large color="error" @click="dialogRemoveProduct(item)">
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ formatMoney(calcTotalProduct(item)) }}
                  </template>
                  <template v-slot:item.pdv="{ item }">
                    {{ pdvDescription(item.pivot.pdv_id) }}
                  </template>
                  <template v-slot:item.qty="{ item }">
                    {{ formatMoney(item.pivot.quantity) }}
                  </template>
                  <template v-slot:item.sale_price="{ item }">
                    {{ formatMoney(item.sale_price) }}
                  </template>
                  <template v-slot:foot="{ item }">
                    <tr style="height:44px; background:#fafafa" class="">
                      <td colspan="3" style="border-top:solid 1px #e4e3e6"></td>
                      <td class="text-right" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">
                        <b>TOTAL:</b>
                      </td>
                      <td class="text-right" style="border-top:solid 1px #e4e3e6 ;padding-left:16px;padding-right:16px">
                        <b>{{ formatMoney(calcTotalProductAmount) }}</b>
                      </td>
                      <td class="text-right" style="border-top:solid 1px #e4e3e6"></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <!-- TABELA SERVIÇOS -->
            <v-tab-item>
              <v-card outlined>
                <v-data-table :headers="headers_service" :items="services" disable-sort class="elevation-0"
                  :footer-props="{
                    itemsPerPageText: 'Itens por pagina',
                    itemsPerPageOptions: [5],
                    showFirstLastPage: true,
                  }">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title>Serviços</v-toolbar-title>
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <!-- <v-spacer></v-spacer> -->
                      <AutocompleteSearchService v-model="service" :dense="true" class=""></AutocompleteSearchService>
                      <v-btn class="ml-2" color="primary" :disabled="!service" @click="showDialogQtyService()">
                        <v-icon>{{ icons.mdiPlus }}</v-icon>Adicionar
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-btn icon large color="error" @click="dialogRemoveService(item)">
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ formatMoney(calcTotalService(item)) }}
                  </template>
                  <template v-slot:item.qty="{ item }">
                    {{ formatMoney(item.pivot.quantity) }}
                  </template>
                  <template v-slot:item.sale_price="{ item }">
                    {{ formatMoney(item.sale_price) }}
                  </template>
                  <template v-slot:foot="{ item }">
                    <tr style="height:44px; background:#fafafa" class="">
                      <td colspan="2" style="border-top:solid 1px #e4e3e6"></td>
                      <td class="text-right" style="border-top:solid 1px #e4e3e6;padding-left:16px;padding-right:16px">
                        <b>TOTAL:</b>
                      </td>
                      <td class="text-right" style="border-top:solid 1px #e4e3e6 ;padding-left:16px;padding-right:16px">
                        <b>{{ formatMoney(calcTotalServiceAmount) }}</b>
                      </td>
                      <td class="text-right" style="border-top:solid 1px #e4e3e6"></td>
                    </tr>
                  </template>
                </v-data-table>
              </v-card>
            </v-tab-item>
            <!-- TABELA HOSPEDES -->
            <v-tab-item>
              <v-card outlined>
                <TableGuestBusy @updateGuests=updateGuest :guests=guests></TableGuestBusy>
              </v-card>
            </v-tab-item>
          </v-tabs-items>

        </v-col>
        <v-col cols="4">
          <v-card elevation="0" outlined style="height:453px">
            <v-card-text>
              <h3>Dados da conta</h3>
              <v-row>
                <v-col>
                  <v-row no-gutters>
                    <v-col>Reserva ID:</v-col>
                    <v-col class="text-right">{{ room.current_reserve_id }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>Comanda ID:</v-col>
                    <v-col class="text-right">{{ room.current_accommodation_id }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>Veículo placa:</v-col>
                    <v-col class="text-right">{{ licensePlate }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>Origem:</v-col>
                    <v-col class="text-right">{{ (room.current_accommodation.company ?
                      room.current_accommodation.company.fantasy_name : '*****').substring(0, 20) }}</v-col>
                  </v-row>
                </v-col>


              </v-row>

              <h3 class="mt-6">Dados da conta</h3>
              <v-row>
                <v-col>
                  <v-row no-gutters>
                    <v-col>Valor quarto:</v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col>Valor Consumo:</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>Impostos:</v-col>
                  </v-row>

                </v-col>

                <v-col>
                  <v-row no-gutters>
                    <v-col class="text-right">R$ {{ formatMoney(room.totalRoomCurrent) }}</v-col>
                  </v-row>
                  <v-row class="text-right" no-gutters>
                    <v-col> R$ {{ formatMoney(room.consumption) }}</v-col>
                  </v-row>
                  <v-row class="text-right" no-gutters>
                    <v-col> R$ {{ formatMoney(getTaxation) }}</v-col>
                  </v-row>

                </v-col>
              </v-row>

              <h3 class="mt-6">Resumo da estadia</h3>
              <v-row>
                <v-col>
                  <v-row no-gutters>
                    <v-col>Entrada:</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>Saída Limite:</v-col>
                  </v-row>

                  <v-row no-gutters>
                    <v-col>Permanência:</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>Permanência em horas:</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>Desconto:</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col>Outros acréscimos:</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col><b>Total da conta:</b></v-col>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row no-gutters>
                    <v-col class="text-right">{{ formatDateTime(room.current_accommodation.date_checkin) }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="text-right">{{ formatDateTime(room.prev_checkout) }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="text-right">{{ room.current_accommodation.permanence }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="text-right">{{ room.current_accommodation.permanence_hours }}</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="text-right">R$ 0,00</v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col class="text-right">R$ 0,00</v-col>
                  </v-row>
                  <v-row class="text-right" no-gutters>
                    <v-col> <b> R$ {{ formatMoney(getTotalToPay) }}</b></v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="my-2" elevation="0" outlined>
            <v-card-text>
              <h3 class="mb-2">Observações:</h3>
              <v-row>
                <v-col>
                  <span v-text="roomObs"></span>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <!-- DIALOG ADICIONAR PRODUTO -->
      <v-dialog v-model="dialogQty" width="500">
        <v-card>
          <BarTitleDialog title="quantidade" @close="dialogQty = false" />
          <v-card-text>
            <v-row no-gutters style="height:90px">
              <v-col cols="8">
                <!-- <v-text-field label="Quantidade" v-model="qtyProduct" outlined></v-text-field> -->
                <vuetify-money :error-messages="errorQtyProduct" v-model="qtyProduct" class="" outlined
                  label="Quantidade" />
              </v-col>
              <v-col class="text-center">
                <v-btn fab color="secondary" class="mr-2 ml-2" @click="minusQtyProduct()">
                  <v-icon>{{ icons.mdiMinus }}</v-icon>
                </v-btn>
                <v-btn fab color="primary" @click="addQtyProduct()">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="col-8">
                <SelectPdv v-model="pdv"></SelectPdv>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogQty = false">Fechar</v-btn>
                <v-btn color="primary" :loading="loadingAddProduct" :disabled="(loadingAddProduct && btnAddQtyProduct)"
                  x-large @click="addProduct()">Adicionar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- REMOVER PRODUCT -->
      <v-dialog v-model="dialogRemoveQty" width="500">
        <v-card>
          <BarTitleDialog title="quantidade" @close="dialogRemoveQty = false" />
          <v-card-text>
            <v-row no-gutters class="mb-8">
              <v-col>
                <h3>Quantidade: {{ formatMoney(qtyCurrentProduct) }}</h3>
              </v-col>
            </v-row>

            <v-row no-gutters style="height:90px">
              <v-col cols="8">
                <vuetify-money :error-messages="errorQtyRemoveProduct" v-model="qtyRemoveProduct" class="" outlined
                  label="Quantidade Retirada" />
              </v-col>
              <v-col class="text-center">
                <v-btn fab color="secondary" class="mr-2 ml-2" @click="minusQtyRemoveProduct()">
                  <v-icon>{{ icons.mdiMinus }}</v-icon>
                </v-btn>
                <v-btn fab color="primary" @click="addQtyRemoveProduct()">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-textarea v-model="obsRemoveProduct" rows="2" outlined label="Observação">
                </v-textarea>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogRemoveQty = false">Fechar</v-btn>
                <v-btn color="error" :loading="loadingRemovedProduct" :disabled="btnQtyRemoveProduct" x-large
                  @click="removeProduct()">remover</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG ADICIONAR SERVIÇO -->
      <v-dialog v-model="dialogQtyService" width="500">
        <v-card>
          <BarTitleDialog title="quantidade" @close="dialogQtyService = false" />
          <v-card-text>
            <v-row no-gutters style="height:90px">
              <v-col cols="8">
                <vuetify-money :error-messages="errorQtyService" v-model="qtyService" class="" outlined
                  label="Quantidade" />
              </v-col>
              <v-col class="text-center">
                <v-btn fab color="secondary" class="mr-2 ml-2" @click="minusQtyService()">
                  <v-icon>{{ icons.mdiMinus }}</v-icon>
                </v-btn>
                <v-btn fab color="primary" @click="addQtyService()">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogQtyService = false">Fechar</v-btn>
                <v-btn color="primary" :disabled="btnAddQtyProduct" x-large @click="addService()">Adicionar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG REMOVE SERVIÇO -->
      <v-dialog v-model="dialogRemoveQtyService" width="500">
        <v-card>
          <BarTitleDialog title="quantidade" @close="dialogRemoveQtyService = false" />
          <v-card-text>
            <v-row no-gutters class="mb-8">
              <v-col>
                <h3>Quantidade: {{ formatMoney(qtyCurrentService) }}</h3>
              </v-col>
            </v-row>

            <v-row no-gutters style="height:90px">
              <v-col cols="8">
                <vuetify-money :error-messages="errorQtyRemoveService" v-model="qtyRemoveService" class="" outlined
                  label="Quantidade Retirada" />
              </v-col>
              <v-col class="text-center">
                <v-btn fab color="secondary" class="mr-2 ml-2" @click="minusQtyRemoveService()">
                  <v-icon>{{ icons.mdiMinus }}</v-icon>
                </v-btn>
                <v-btn fab color="primary" @click="addQtyRemoveService()">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-textarea v-model="obsRemoveService" rows="2" outlined label="Observação">

                </v-textarea>
              </v-col>
            </v-row>

          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogRemoveQtyService = false">Fechar</v-btn>
                <v-btn color="error" :loading="loadingRemovedService" :disabled="btnQtyRemoveService" x-large
                  @click="removeService()">remover</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG TRANSFERENCIA DE QUARTO -->
      <v-dialog v-model="dialogTranfRoom" width="600">
        <v-card>
          <BarTitleDialog title="Transferir Quarto" @close="dialogTranfRoom = false"></BarTitleDialog>
          <v-card-text>
            <v-row>
              <v-col>
                <SelectRoom @change="errorRoomTransf = null" :errors="errorRoomTransf" v-model="roomId"></SelectRoom>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-textarea @input="errorTransfJustification = null" :error-messages="errorTransfJustification" outlined
                  rows="2" v-model="transfJustification" label="Justificativa"></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogTranfRoom = false">Fechar</v-btn>
                <v-btn color="primary" :loading="loadingTransf" :disabled="loadingTransf" x-large
                  @click="sendTransfRoom()">Transferir</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG EDITAR CHECKOUT e CHECKOUT -->
      <v-dialog v-model="dialogEditCheckout" width="600">
        <v-card>
          <BarTitleDialog title="Editar Checkout" @close="dialogEditCheckout = false"></BarTitleDialog>
          <v-card-text>
            <v-row no-gutters v-for="item in accommodation_items" class="mb-2" :key="index">
              <v-col>
                <vuetify-money :option="{ prefix: 'R$' }" v-model="item.value" dense outlined
                  hide-details></vuetify-money>
              </v-col>
              <v-col>
                <v-text-field hide-details="" type="datetime-local" @blur="changeAccomodation()" :readonly="IfOneItem"
                  class="ml-2" outlined dense v-model="item.date_start" label="Data Inicio"></v-text-field>
                <!-- <input-date css="ml-2" v-model="tariff.date_start" :label="'Data Inicial'" :dense="true"></input-date> -->
              </v-col>
              <v-col>
                <v-text-field hide-details="" type="datetime-local" readonly class="ml-2" outlined dense
                  :value="item.date_end" label="Data Fim"></v-text-field>
                <!-- <input-date css="ml-2" v-model="tariff.date_end" :label="'Data Final'" :dense="true"></input-date> -->
              </v-col>
              <!-- <v-col cols="1"> -->
              <!-- <v-btn icon color="error" class="ml-2" @click="removeTariffExtraordinary(tariff)" >
                                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                            </v-btn> -->
              <!-- </v-col> -->
            </v-row>
            <v-row>
              <v-col class='text-right'>
                <v-btn color="primary" class="ml-2" @click="addAccommodationItem()">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>Adicionar Diária
                </v-btn>
              </v-col>
              <v-col>
                <v-btn color="error" class="ml-2" @click="removeAccommodationItem()">
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>Remover Diária
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-text>
            <v-data-table hide-default-footer :headers="headers_observation" :items="accommodation_observations"
              class="elevation-1" dense hide-default-header>
              <template v-slot:no-data>
                <NoDataTable></NoDataTable>
              </template>
              <template v-slot:top>
                <v-toolbar flat dense>
                  <v-toolbar-title>Observações:</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-btn icon large color="primary" @click="showDialogObsCheckout(item)">
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn icon large color="error" @click="removeObservations(item)">
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogEditCheckout = false">Fechar</v-btn>
                <v-btn color="primary" :loading="loadingEditCheckout" :disabled="loadingEditCheckout" x-large
                  @click="saveEditCheckout()">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG CONSUMO HOSPEDE -->
      <!--DIALOG DETALHES DO QUARTO  -->
      <v-dialog v-model="dialogConsumption" width="700">
        <v-card>
          <BarTitleDialog :title="'Consumo do quarto - ' + roomDescription" @close="dialogConsumption = false">
          </BarTitleDialog>
          <v-card-text>
            <v-row no-gutters>
              <v-col>
                <h3>Hospedagem:</h3>
                <v-row no-gutters>
                  <v-col cols="7"><b>DESCRIÇÃO</b></v-col>
                  <v-col class="text-right"><b>QTD.</b></v-col>
                  <v-col class="text-right"><b>P.UNIT</b></v-col>
                  <v-col class="text-right"><b>SUB. TOTAL</b></v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters v-for="(accommodation, index) in room.accommodationItemsCurrent" :key="index">
                  <v-col cols="7">HOSPEDAGEM {{ formatDate(accommodation.date_start) + '
                    - '+formatDate(accommodation.date_end)}}</v-col>
                  <v-col class="text-right">1,00</v-col>
                  <v-col class="text-right">{{ formatMoney(accommodation.value) }}</v-col>
                  <v-col class="text-right">{{ formatMoney(accommodation.value) }}</v-col>
                </v-row>
                <v-row no-gutters v-for="(accommodation, index) in room.current_accommodation.extra_items" :key="index">
                  <v-col cols="7">{{ accommodation.description }}</v-col>
                  <v-col class="text-right">1,00</v-col>
                  <v-col class="text-right">{{ formatMoney(accommodation.value) }}</v-col>
                  <v-col class="text-right">{{ formatMoney(accommodation.value) }}</v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="9"><b>Total (R$):</b></v-col>
                  <v-col><b></b></v-col>
                  <v-col><b></b></v-col>
                  <v-col class="text-right"><b>{{ formatMoney(totalAccommodation) }}</b></v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col>

                <h3>Produtos:</h3>
                <v-row no-gutters>
                  <v-col cols="7"><b>DESCRIÇÃO</b></v-col>
                  <v-col class="text-right"><b>QTD.</b></v-col>
                  <v-col class="text-right"><b>P.UNIT</b></v-col>
                  <v-col class="text-right"><b>SUB. TOTAL</b></v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters v-for="(product, index) in room.current_accommodation.product" :key="index">
                  <v-col cols="7">{{ product.description }}</v-col>
                  <v-col class="text-right">{{ formatMoney(product.pivot.quantity) }}</v-col>
                  <v-col class="text-right">{{ formatMoney(product.sale_price) }}</v-col>
                  <v-col class="text-right">{{ formatMoney(product.sale_price * product.pivot.quantity) }}</v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="9"><b>Total (R$):</b></v-col>
                  <v-col><b></b></v-col>
                  <v-col><b></b></v-col>
                  <v-col class="text-right"><b>{{ formatMoney(totalProduct) }}</b></v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <h3>Serviços:</h3>
                <v-row no-gutters>
                  <v-col cols="7"><b>DESCRIÇÃO</b></v-col>
                  <v-col class="text-right"><b>QTD.</b></v-col>
                  <v-col class="text-right"><b>P.UNIT</b></v-col>
                  <v-col class="text-right"><b>SUB. TOTAL</b></v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters v-for="(service, index) in room.current_accommodation.service" :key="index">
                  <v-col cols="7">{{ service.description }}</v-col>
                  <v-col class="text-right">{{ formatMoney(service.pivot.quantity) }}</v-col>
                  <v-col class="text-right">{{ formatMoney(service.sale_price) }}</v-col>
                  <v-col class="text-right">{{ formatMoney(service.sale_price * service.pivot.quantity) }}</v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters>
                  <v-col cols="9"><b>Total (R$):</b></v-col>
                  <v-col><b></b></v-col>
                  <v-col><b></b></v-col>
                  <v-col class="text-right"><b>{{ formatMoney(totalService) }}</b></v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="text-right" style="font-size:16px"> Impostos (R$): <div
                  style="float:right;width:70px"> {{ formatMoney(room.tax) }}</div></v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" class="text-right" style="color:black;font-size:16px"><b> Total (R$): <div
                    style="float:right;width:70px">{{ formatMoney(totalAmount) }}</div></b></v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn class="mr-2" color="secondary" :loading="loadingPrintAcommodation"
                  :disabled="loadingPrintAcommodation" x-large @click="printAccommodation()"><v-icon>{{ icons.mdiPrinter
                    }}</v-icon>&nbsp Imprimir Hospedagem</v-btn>
                <v-btn color="primary" :loading="loadingPrintConsumption" :disabled="loadingPrintConsumption" x-large
                  @click="printDetails()"><v-icon>{{ icons.mdiPrinter }}</v-icon>&nbsp Imprimir COnsumo</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <!-- DIALOG OBSERVAÇÃO -->
      <v-dialog v-model="dialogObs" width="600">
        <v-card>
          <BarTitleDialog title="Observações" @close="dialogObs = false"></BarTitleDialog>
          <v-card-text>
            <v-row no-gutters>
              <v-col>
                <v-textarea :error-messages="errorTransfJustification" outlined rows="3" v-model="roomObs"
                  label="Observação"></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogObs = false">Fechar</v-btn>
                <v-btn color="primary" :loading="loadingTransf" :disabled="loadingTransf" x-large
                  @click="saveObs()">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG OBSERVAÇÃO -->
      <v-dialog v-model="dialogObsCheckout" width="600">
        <v-card>
          <BarTitleDialog title="Observações" @close="dialogObsCheckout = false"></BarTitleDialog>
          <v-card-text>
            <v-row no-gutters>
              <v-col>
                <v-textarea :error-messages="errorTransfJustification" outlined rows="2" v-model="onservation_input"
                  label="Observação"></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogObsCheckout = false">Fechar</v-btn>
                <v-btn color="primary" :loading="loadingTransf" :disabled="loadingTransf" x-large
                  @click="addObservation()">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG Alterar Placa -->
      <v-dialog v-model="dialogChangeLicensePlate" width="600">
        <v-card>
          <BarTitleDialog title="Alterar Placa Veículo" @close="dialogChangeLicensePlate = false"></BarTitleDialog>
          <v-card-text>
            <v-row no-gutters>
              <v-col>
                <v-text-field label="Placa do veículo" v-model="licensePlate" outlined></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogChangeLicensePlate = false">Fechar</v-btn>
                <v-btn color="primary" x-large @click="saveLicensePlate()">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- DIALOG  AUTHORIZATION  -->
      <v-dialog v-model="dialogAuthorization" width="600">
        <v-card>
          <BarTitleDialog title="Autorizar" @close='dialogAuthorization = false' />
          <v-card-text>
            <v-row class="mt-3">
              <v-col>
                <v-text-field v-model="admLogin.email" outlined label="Email" placeholder="Digite seu email"
                  hide-details="auto" class="mb-6 mt-2" :error-messages="message_error_email" @keyup="validEmail" />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col>
                <v-text-field outlined v-model="admLogin.password" label="Senha" type="password"
                  placeholder="Digite sua senha" hide-details="auto" :error-messages="message_error_email"
                  @keyup="validPassword" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col class="text-right">
                <v-btn text class="mr-2" x-large @click="dialogAuthorization = false">Cancelar</v-btn>
                <v-btn color="primary" x-large @click="requestAuthorization()">Autorizar</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- DIALOG Exta Diaria -->
      <v-dialog v-model="dialogExtra" width="600">
        <v-card>
          <BarTitleDialog title="Tarifas Extraordinárias" @close="dialogExtra = false"></BarTitleDialog>
          <v-card-text>
            <v-row no-gutters v-for="item in accommodation_exta" class="mb-2" :key="index">
              <v-col cols="3">
                <vuetify-money 
                 :option="{ prefix: 'R$' }" v-model="item.value" dense outlined
                  hide-details></vuetify-money>
              </v-col>
              <v-col cols="8">
                <v-text-field hide-details="" @blur="" class="ml-2" outlined dense v-model="item.description"
                  label="Descrição"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class='text-center'>
                <v-btn color="primary" class="ml-2" @click="addExtraItem()">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>Adicionar Tarifa
                </v-btn>
              </v-col>
              <v-col class='text-center'>
                <v-btn color="error" class="ml-2" @click="addExtraItemDiscount()">
                  <v-icon>{{ icons.mdiPlus }}</v-icon>Adicionar Desconto
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogEditCheckout = false">Fechar</v-btn>
                <v-btn color="primary" :loading="loadingEditCheckout" :disabled="loadingEditCheckout" x-large
                  @click="updateExtraTariff()">Salvar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

       <!-- DIALOG Avulsa -->
       <v-dialog v-model="dialogOutstanding" width="600">
        <v-card>
          <BarTitleDialog title="Migrar para conta avulsa?" @close="dialogOutstanding = false"></BarTitleDialog>
          <v-card-text>
            <v-row >
              <v-col>
                <span class='text-body-1'>Confirma migrar para conta avulsa?</span>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col class="text-center">
                <v-btn  x-large color='error' class="mr-4" @click="dialogOutstanding = false">Cancelar</v-btn>
                <v-btn color="primary" :loading="loadingEditCheckout" :disabled="loadingEditCheckout" x-large
                  @click="moveOutstanding()">Confirmar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Cancelar Checkin -->
      <v-dialog v-model="dialogCancel" width="600">
        <v-card>
          <BarTitleDialog title="Cancelar CheckIn" @close="dialogCancel = false"></BarTitleDialog>
          <v-card-text>
            <v-row no-gutters>
              <v-col>
                <v-textarea @input="errorCancelInput = null" :error-messages="errorCancelInput" outlined
                  rows="2" v-model="cancelInput" label="Justificativa"></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-row>
              <v-col class="text-right">
                <v-btn text x-large class="mr-4" @click="dialogCancel = false">Fechar</v-btn>
                <v-btn color="primary" :loading="loadingTransf" :disabled="loadingTransf" x-large
                  @click="sendCancelChekin()">Confirmar</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

      

    </v-card-text>
  </div>
</template>

<script>
import { mdiDeleteOutline, mdiPlus, mdiMinus, mdiMenu } from '@mdi/js'
import AutocompleteSearchProduct from '@/components/AutocompleteSearchProduct.vue'
import AutocompleteSearchService from '@/components/AutocompleteSearchService.vue'
import TableGuestBusy from './TableGuestBusy.vue'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import SelectRoom from '@/components/SelectRoom.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import SelectPdv from '@/components/SelectPdv.vue'
import room from '@/store/register/room/room'
import pdv from '@/store/register/pdv/pdv'
import reserve from '@/store/reserve/reserve'
export default {
  components: {
    BarTitleDialog,
    AutocompleteSearchProduct,
    SelectRoom,
    AutocompleteSearchService,
    SelectPdv,
    TableGuestBusy,
  },
  props: {
    room: {
      type: Object,
    },
    value: {
      type: Boolean,
    },
  },
  data: () => ({
    //DIALOG CONSUMPTION
    dialogConsumption: false,
    loadingPrintConsumption: false,
    loadingPrintAcommodation: false,
    roomDescription: "",
    
    //DIALOG CANCELAR
    dialogCancel: false,
    errorCancelInput: null,
    cancelInput:'',


    //Admin Status
    admRole: {},
    dialogAuthorization: false,
    message_error_email: '',
    message_error_password: '',
    admLogin: {
      email: '',
      password: '',
    },
    //Extra Diaria
    dialogExtra: false,
    accommodation_exta: [],

    //Avulsa
    dialogOutstanding: false,

    //Observações
    accommodation_observations: [],
    onservation_input: '',
    headers_observation: [
      {
        text: 'Observações',
        sortable: false,
        align: 'start',
        value: 'description',
      },
      {
        text: '',
        align: 'end',
        value: 'action',
      },
    ],
    loadingAddProduct: false,
    tab: null,
    loadingTransf: false,
    roomId: null,
    transfJustification: '',
    errorTransfJustification: null,
    errorRoomTransf: null,
    dialogTranfRoom: false,
    errorQtyRemoveProduct: null,
    dialogRoom: false,
    descriptionRoom: '',
    editedItemLoading: false,
    //PAGAMNETO PARCIAL
    loadingPayment: false,
    //PRODUTO
    loadingRemovedProduct: false,
    obsRemoveProduct: '',
    btnQtyRemoveProduct: false,
    dialogQty: false,
    dialogRemoveQty: false,
    qtyRemoveProduct: 1,
    qtyCurrentProduct: 0,
    productRemove: {},
    products: [],
    qtyProduct: 1,
    product: null,
    errorQtyProduct: null,
    btnAddQtyProduct: false,
    loadingPrintProduct: false,
    productsPrint: [],
    pdv: 1,

    //EDITAR CHECKOUT
    dialogEditCheckout: false,
    accommodation_items: [],
    loadingEditCheckout: false,

    //LANÇAMENTO
    postedItems: [],

    //SERVIÇO
    services: [],
    service: null,
    dialogQtyService: false,
    qtyService: 1,
    errorQtyService: null,
    dialogRemoveQtyService: false,
    qtyRemoveService: 1,
    errorQtyRemoveService: null,
    qtyCurrentService: 0,
    obsRemoveService: '',
    loadingRemovedService: false,
    btnQtyRemoveService: false,
    serviceRemove: {},

    //DIALOG Observação
    dialogObs: false,
    roomObs: '',

    //DIALOG Authorization
    dialogAuthorization: false,

    //DIALOG Alterar Placa
    dialogChangeLicensePlate: false,
    licensePlate: '',

    dialogObsCheckout: false,

    //HOSPEDE
    guests: [],
    headers_guests: [
      {
        text: 'NOME',
        align: 'start',
        value: 'name',
      },
      {
        text: 'TELEFONE',
        value: 'cell_phone',
      },
      {
        text: 'EMAIL',
        value: 'email',
      },
    ],

    headers: [
      {
        text: 'PRODUTO',
        align: 'start',
        value: 'description',
      },
      {
        text: 'Ponto de Venda',
        align: 'center',
        value: 'pdv',
      },
      {
        text: 'QTDE',
        align: 'center',
        value: 'qty',
      },
      {
        text: 'P. UND. (R$)',
        align: 'right',
        value: 'sale_price',
      },
      {
        text: 'TOTAL',
        align: 'right',
        value: 'total',
      },
      {
        text: '',
        align: 'end',
        value: 'action',
      },
    ],
    headers_service: [
      {
        text: 'PRODUTO',
        align: 'start',
        value: 'description',
      },

      {
        text: 'QTDE',
        align: 'center',
        value: 'qty',
      },
      {
        text: 'P. UND. (R$)',
        align: 'right',
        value: 'sale_price',
      },
      {
        text: 'TOTAL',
        align: 'right',
        value: 'total',
      },
      {
        text: '',
        align: 'end',
        value: 'action',
      },
    ],
    statusItems: [
      {
        value: 'sujo',
        text: 'Sujo',
      },
      {
        value: 'bloqueado',
        text: 'Bloqueado',
      },
      {
        value: 'diaria',
        text: 'Diaria',
      },
      {
        value: 'disponivel',
        text: 'Disponível',
      },
      {
        value: 'conferencia',
        text: 'Conferência',
      },
      {
        value: 'encerramento',
        text: 'Encerramento',
      },
      {
        value: 'limpeza',
        text: 'Limpeza',
      },
      {
        value: 'manutencao',
        text: 'Manutenção',
      },
      {
        value: 'ocupado',
        text: 'Ocupado',
      },
      {
        value: 'periodo',
        text: 'Período',
      },
      {
        value: 'pernoite',
        text: 'Pernoite',
      },
      {
        value: 'reservado',
        text: 'Reservado',
      },
      {
        value: 'stand-by',
        text: 'Stand-by',
      },
    ],
    rooms: [],
    editedItem: {},
    indexItem: null,
    icons: {
      mdiDeleteOutline,
      mdiPlus,
      mdiMinus,
      mdiMenu,
    },
  }),
  watch: {
    value(val) {
      if (val) {
        this.products = this.room.current_accommodation.product
        this.services = this.room.current_accommodation.service
        this.guests = this.room.current_accommodation.guest
        this.roomObs = this.room.current_accommodation.observation
        this.loadObservations(this.room.current_accommodation.id)
        this.storeCheckout()
        this.loadRooms('disponivel') //carrega todos os quartos disponivel da tela de transferir quarto
        this.consumptionAlert()
      }
    },
    qtyProduct() {
      if (this.qtyProduct > 0) {
        this.errorQtyProduct = null
        this.btnAddQtyProduct = false
      } else {
        this.errorQtyProduct = 'Quantidade deve ser maior que zero'
        this.btnAddQtyProduct = true
      }
    },
    qtyRemoveProduct() {
      if (this.qtyRemoveProduct <= this.qtyCurrentProduct) {
        this.errorQtyRemoveProduct = null
        this.btnQtyRemoveProduct = false
      } else {
        this.errorQtyRemoveProduct = 'Quantidade retirada dever ser menor ou igual a quantidade atual'
        this.btnQtyRemoveProduct = true
      }
    },
    // products(val){
    //   this.$emit('input', val)
    // }
  },

  created() {
    if (this.value) {
      this.products = this.room.current_accommodation.product
      this.services = this.room.current_accommodation.service
      this.guests = this.room.current_accommodation.guest
      this.roomDescription = this.room.description
      this.licensePlate = this.room.current_accommodation.car_license_plate
      this.storeCheckout()
      this.loadRooms('disponivel')
      this.loadObservations(this.room.current_accommodation.id)
      this.loadCurrentRole()
      this.verifyReserveStatus()
    }
    this.loadPdvs()
    this.inicialize()
  },
  computed: {
    ...mapGetters('checkout', ['getSubtotal', 'getTotalToPay', 'getTaxation']),
    ...mapGetters('select', ['getPdvs']),
    calcTotalProductAmount() {
      const result = this.products.reduce((acc, product) => {
        return acc + this.calcTotalProduct(product)
      }, 0)
      return result
    },
    calcTotalServiceAmount() {
      const result = this.services.reduce((acc, service) => {
        return acc + this.calcTotalService(service)
      }, 0)
      return result
    },

    calcTotalAccommodation() {
      return this.calcTotalProductAmount + this.room.current_accommodation.accommodation_value
    },
    itemsProducts() {
      // let products_agruped = []
      // this.products.forEach(item => {
      //   let product = products_agruped.find(product => {
      //     return product.id == item.id
      //   })
      //   if (product) {
      //     // se exitir mais de item do mesmo produto soma só a quantidade
      //     product.pivot.quantity += item.pivot.quantity
      //   } else {
      //     products_agruped.push(Object.assign({}, item))
      //   }
      // })
      // return products_agruped
      return this.products
    },
    totalProduct() {
      return this.room.current_accommodation.product.reduce((acc, product) => {
        return acc + parseFloat(product.pivot.quantity) * parseFloat(product.sale_price)
      }, 0)
    },
    totalService() {
      return this.room.current_accommodation.service.reduce((acc, service) => {
        return acc + parseFloat(service.pivot.quantity) * parseFloat(service.sale_price)
      }, 0)
    },
    totalAccommodation() {
      const accommodation_items_value = this.room.accommodationItemsCurrent.reduce((acc, accomodation) => {
        return acc + parseFloat(accomodation.value)
      }, 0)
      const accommodation_exta_value = this.room.current_accommodation.extra_items.reduce((acc, accomodation) => {
        return acc + parseFloat(accomodation.value)
      }, 0)
      return accommodation_items_value + accommodation_exta_value
    },
    totalAmount() {
      return (
        parseFloat(this.totalProduct) +
        parseFloat(this.totalService) +
        parseFloat(this.totalAccommodation) +
        parseFloat(this.room.tax)
      )
    },
    IfOneItem() {
      if (this.accommodation_items.length == 1) {
        return false
      }
      return true
    },
  },
  methods: {
    ...mapActions('outstanding', ['storeOutstanding']),
    ...mapActions('open_account', ['exportDetailsPdf']),
    ...mapActions('select', ['loadRooms', 'loadPdvs']),
    ...mapActions('manager_room', ['getAllRoom']),
    ...mapActions('busy', [
      'updateRoomBusy',
      'storeItemRemoved',
      'storeTransfRoom',
      'printProduct',
      'updateEditCheckout',
      'exportAccommodationPdf',
      'updateObservations',
      'getAccommodationObservations',
      'alertConsumptionLimit',
      'updateExtraTarrif',
      'cancelCheckin',
      'updateReserve'
    ]),
    ...mapMutations('checkout', [
      'setRoom',
      'setCheckout',
      'setStage',
      'clearCheckoutSeparete',
      'setOriginPartialPayment',
    ]),
    ...mapActions('checkout', [
      'getUser',
    ]),
    ...mapActions('select', ['loadAdmRole']),
    //modficar data primeiro data em editar checkout
    changeAccomodation() {
      let next_date = new Date(this.accommodation_items[0].date_start)
      next_date.setDate(next_date.getDate() + 1)
      let next_date1 = next_date.toISOString().substring(0, 10) + ' 14:00:00'

      this.accommodation_items[0].date_end = next_date1
    },
    //imprimir
    printDetails() {
      this.loadingPrintConsumption = true
      this.exportDetailsPdf(this.room)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {

        })
        .finally(() => {
          this.loadingPrintConsumption = false
        })
    },
    printAccommodation() {
      this.loadingPrintAcommodation = true
      this.exportAccommodationPdf(this.room)
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'export.pdf')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
        .catch(response => {

        })
        .finally(() => {
          this.loadingPrintAcommodation = false
        })
    },
    //Add Guest
    updateGuest() {
      this.save()
    },
    //DIALOG DE EDITAR CHECKOUT
    showEditCheckout() {
      this.dialogEditCheckout = true
      this.accommodation_items = this.room.current_accommodation.accommodation_item
    },
    //dialogConsumption
    showDialogConsumption() {
      this.dialogConsumption = true
    },

    showDialogObs() {
      this.dialogObs = true
    },
    showdialogChangeLicensePlate() {
      this.dialogChangeLicensePlate = true
    },

    addAccommodationItem() {
      let date_end = new Date(this.accommodation_items[this.accommodation_items.length - 1].date_end)
      let date_end1 = date_end.toISOString().substring(0, 10) + ' 14:00:00'
      let next_date = new Date(date_end1)
      next_date.setDate(date_end.getDate() + 1)
      let next_date1 = next_date.toISOString().substring(0, 10) + ' 14:00:00'

      this.accommodation_items.push({ value: 0, date_start: date_end1, date_end: next_date1 })
    },
    removeAccommodationItem() {
      if (this.admRole.authorization) {
        if (this.accommodation_items.length > 1) {
          this.accommodation_items.pop()
        }
      } else {
        this.dialogAuthorization = true
      }

    },
    saveEditCheckout() {

      const data = {
        accommodation_id: this.room.current_accommodation.id,
        accommodation_item: this.accommodation_items,
      }

      this.loadingEditCheckout = true
      this.updateEditCheckout(data)
        .then(response => {
          this.$toast.success(' Data checkout atualizado. ')
          this.dialogEditCheckout = false
          this.room.accommodationItemsCurrent = response.data.accommodationItemsCurrent
          this.room.totalRoomCurrent = response.data.totalRoomCurrent
          this.storeCheckout()
        })
        .catch(() => { })
        .finally(() => {
          this.loadingEditCheckout = false
          this.loadCurrentRole()
        })

    },

    sendPrintProduct() {
      this.loadingPrintProduct = true
      const data = {
        accommodation_id: this.room.current_accommodation_id,
      }

      this.printProduct(data)
        .then(response => {
          this.$toast.success('Pedido Enviado !')
        })
        .finally(() => {
          this.loadingPrintProduct = false
        })
    },
    inicialize() {
      this.getAllRoom().then(response => {
        this.rooms = response.data
      })

    },
    //TRASNFERENCIA DE QUARTO
    showTranfRoom() {
      this.dialogTranfRoom = true
      this.loadRooms('disponivel')
    },
    sendTransfRoom() {
      //valida quarto
      if (this.roomId == null || this.roomId == undefined) {
        this.errorRoomTransf = 'Campo Obrigatório'
        return false
      }
      //valida a justificativa
      if (this.transfJustification == '') {
        this.errorTransfJustification = 'Campo Obrigatório'
        return false
      }

      this.loadingTransf = true
      const transf = {
        accommodation_id: this.room.current_accommodation.id,
        room_id: this.roomId,
        justification: this.transfJustification,
      }
      this.storeTransfRoom(transf)
        .then(response => {
          this.dialogTranfRoom = false
          this.roomId = ''
          this.$emit('updategrid')
          this.$emit('close')
          this.$toast.success('Transferido com sucesso')
        })
        .finally(() => {
          this.loadingTransf = false
        })
    },
    // PRODUTOS
    dialogQtyProduct() {
      this.dialogQty = true
    },
    addProduct() {
      if (this.qtyProduct == 0) {
        this.errorQtyProduct = 'Quantidade deve ser maior que zero'
        return false
      }
      this.product.pivot = {
        quantity: 0,
      }

      this.product.pivot.quantity = this.qtyProduct
      this.productsPrint.push(this.product)

      let postedItem = {
        accommodation_id: this.room.current_accommodation_id,
        product_id: this.product.id,
        quantity: this.qtyProduct,
        pdv_id: this.pdv,
        sale_price: this.product.sale_price,
      }
      this.postedItems.push(postedItem)

      //verifica se exite o produto na lista
      let productExist = this.products.find(item => {
        return this.product.id == item.id && item.pivot.pdv_id == this.pdv
      })

      if (productExist) {
        productExist.pivot.quantity += this.qtyProduct
      } else {
        this.product.pivot.pdv_id = this.pdv
        this.products.push(this.product)
      }

      this.product = ''
      this.qtyProduct = 1

      this.save()
    },
    addQtyProduct() {
      this.qtyProduct++
    },
    minusQtyProduct() {
      if (this.qtyProduct > 0) {
        this.qtyProduct--
      }
    },
    dialogRemoveProduct(product) {
      this.productRemove = product
      this.dialogRemoveQty = true
      this.qtyCurrentProduct = product.pivot.quantity
      this.obsRemoveProduct = ''
    },
    minusQtyRemoveProduct() {
      if (this.qtyRemoveProduct > 0) {
        this.qtyRemoveProduct--
      }
    },
    addQtyRemoveProduct() {
      if (this.qtyRemoveProduct < this.qtyCurrentProduct) {
        this.qtyRemoveProduct++
      }
    },
    removeProduct() {
      if (this.qtyRemoveProduct > this.qtyCurrentProduct) {
        this.errorQtyRemoveProduct = 'Quantidade retirada dever ser menor ou igual a quantidade atual'
      }

      const removeProduct = {
        accommodation_id: this.room.current_accommodation_id,
        product_id: this.productRemove.id,
        quantity: this.qtyRemoveProduct,
        obs: this.obsRemoveProduct,
        pdv_id: this.productRemove.pivot.pdv_id,
      }

      this.loadingRemovedProduct = true
      this.btnQtyRemoveProduct = true

      this.storeItemRemoved(removeProduct)
        .then(response => {
          // this.$toast.success('Salvo com sucesso')
          this.dialogRemoveQty = false
          this.productRemove.pivot.quantity -= this.qtyRemoveProduct

          /*
          if (this.productRemove.pivot.quantity == 0) {
            const index = this.products.indexOf(this.productRemove)
            this.products.splice(index, 1)
          }
          */

          this.save()
        })
        .finally(() => {
          this.loadingRemovedProduct = false
          this.btnQtyRemoveProduct = false
        })
    },
    calcTotalProduct(product) {
      return product.pivot.quantity * product.sale_price
    },
    // SERVICOS
    showDialogQtyService() {
      this.dialogQtyService = true
    },

    addQtyService() {
      this.qtyService++
    },
    minusQtyService() {
      if (this.qtyService > 0) {
        this.qtyService--
      }
    },
    addService() {
      if (this.qtyService == 0) {
        this.errorQtyService = 'Quantidade deve ser maior que zero'
        return false
      }
      this.service.pivot = {
        quantity: 0,
      }
      this.service.pivot.quantity = this.qtyService
      this.services.push(this.service)

      let postedItem = {
        accommodation_id: this.room.current_accommodation_id,
        service_id: this.service.id,
        quantity: this.qtyService,
        sale_price: this.service.sale_price,
      }

      this.postedItems.push(postedItem)

      this.dialogQtyService = false
      this.service = ''
      this.qtyService = 1
      this.save()
    },
    calcTotalService(service) {
      return service.pivot.quantity * service.sale_price
    },
    dialogRemoveService(service) {
      this.serviceRemove = service
      this.dialogRemoveQtyService = true
      this.qtyCurrentService = service.pivot.quantity
      this.obsRemoveService = ''
    },
    minusQtyRemoveService() {
      if (this.qtyRemoveService > 0) {
        this.qtyRemoveService--
      }
    },
    addQtyRemoveService() {
      if (this.qtyRemoveService < this.qtyCurrentService) {
        this.qtyRemoveService++
      }
    },
    removeService() {
      if (this.qtyRemoveService > this.qtyCurrentService) {
        this.errorQtyRemoveService = 'Quantidade retirada dever ser menor ou igual a quantidade atual'
      }

      const removeService = {
        accommodation_id: this.room.current_accommodation_id,
        service_id: this.serviceRemove.id,
        quantity: this.qtyRemoveService,
        obs: this.obsRemoveService,
      }

      this.loadingRemovedService = true
      this.btnQtyRemoveService = true
      this.storeItemRemoved(removeService)
        .then(response => {
          // this.$toast.success('Salvo com sucesso')
          this.dialogRemoveQtyService = false
          this.serviceRemove.pivot.quantity -= this.qtyRemoveService

          //se quantidade for zero tira o produto da lista
          if (this.serviceRemove.pivot.quantity == 0) {
            const index = this.services.indexOf(this.serviceRemove)
            this.services.splice(index, 1)
          }

          this.save()
        })
        .finally(() => {
          this.loadingRemovedService = false
          this.btnQtyRemoveService = false
        })
    },
    // changeQtyProduct(){
    //   if (this.qtyProduct > 0) {
    //     this.errorQtyProduct = null
    //   }
    // },
    save(room) {
      this.room.products = this.products
      this.room.services = this.services
      this.room.products_print = this.productsPrint
      this.room.posted_items = this.postedItems
      this.room.guests = this.guests
      this.room.observation = this.roomObs
      this.room.licensePlate = this.licensePlate
      this.editedItemLoading = true
      this.updateRoomBusy(this.room)
        .then(response => {
          this.$emit('updategrid')
          //atualiaza os produtos do quarto no front-end
          const products = response.data.current_accommodation.product
          // this.room.current_accommodation.product = products
          // this.products = products
          this.dialogQty = false //didalog adicionar produtos
          //this.$emit('close')
          this.productsPrint = []
          this.postedItems = []
          this.$toast.success('Salvo com sucesso')
          this.storeCheckout()
        })
        .finally(() => {
          this.cleanProducts()
          this.loadingAddProduct = false
        })
    },

    saveObs() {
      this.dialogObs = false
      this.save()
    },
    saveLicensePlate() {
      this.dialogChangeLicensePlate = false
      this.save()
    },
    closeAccount() {
      this.$emit('close')
      this.$router.push({
        name: 'checkout',
      })
      this.setStage('payment')
      // this.setRoom(this.room)

      this.clearCheckoutSeparete()
      this.storeCheckout()
      this.setOriginPartialPayment('checkout')
    },

    partialPayment() {
      this.$emit('close')
      this.$router.push({
        name: 'checkout',
      })
      this.setStage('payment')
      this.clearCheckoutSeparete()
      this.storeCheckout()
      this.setOriginPartialPayment('accommodation')
    },

    storeCheckout() {
      this.setRoom(this.room)
      this.products.map(product => (product.origin = 'product')) //propriedade define no checkout quem é produto ou serviço
      this.services.map(service => (service.origin = 'service')) //propriedade define no checkout quem é produto ou serviço
      this.room.accommodationItemsCurrent.map(item => {
        item.origin = 'accommodation'
        item.pivot = { quantity: 1 }
        item.sale_price = item.value
        item.description = 'HOSPEDAGEM ' + this.formatDate(item.date_start) + ' - ' + this.formatDate(item.date_end)
        return item
      })
      this.room.current_accommodation.extra_items.map(item => {
        item.origin = 'extra_tarif'
        item.pivot = { quantity: 1 }
        item.sale_price = item.value
        return item
      })
      const accommodation_item = this.room.accommodationItemsCurrent
      const accommodation_exta = this.room.current_accommodation.extra_items
      this.setCheckout(this.products.concat(this.services, accommodation_item, accommodation_exta)) //junta produto e servico para mostrar no checkout
    },
    status(status) {
      switch (status) {
        case 'sujo':
          return 'Sujo'
        case 'bloqueado':
          return 'Bloqueado'
        case 'diaria':
          return 'Diária'
        case 'disponivel':
          return 'Disponível'
        case 'conferencia':
          return 'Conferência'
        case 'encerramento':
          return 'Encerramento'
        case 'limpeza':
          return 'Limpeza'
        case 'manutencao':
          return 'Manutenção'
        case 'ocupado':
          return 'Ocupado'
        case 'periodo':
          return 'Período'
        case 'pernoite':
          return 'Pernoite'
        case 'reservado':
          return 'Reservado'
        case 'stand-by':
          return 'Stand-by'
      }
    },
    formatMoney(value) {
      value = parseFloat(value)
      if (value != null) {
        return value.toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      }
    },
    formatDateTime(date) {
      if (date) {
        const result = new Date(date)
        return (
          result.toLocaleDateString('pt-BR', {
            timeZone: 'America/Sao_Paulo',
          }) +
          ' ' +
          result.toLocaleTimeString('pt-BR')
        )
      }
    },
    cleanProducts() {
      let product = this.products.find(product => product.pivot.quantity == 0)
      if (product) {
        const index = this.products.indexOf(product)
        this.products.splice(index, 1)
      }
    },
    pdvDescription(pdv_id) {
      const pdv = this.getPdvs.find(pdv => pdv.id == pdv_id)
      return pdv ? pdv.description : ''
    },
    formatDate(date) {
      if (date) {
        const result = new Date(date)
        return result.toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' })
      }
    },

    //AUTHORIZATION
    requestAuthorization() {
      if (!this.validEmail() || !this.validPassword()) return;
      var credentials = {
        email: this.admLogin.email,
        password: this.admLogin.password
      }
      this.loadAdmRole(credentials).then(response => {
        this.admRole = response.data;

        if (this.admRole.authorization) {
          this.dialogAuthorization = false;
          this.$toast.success('Usuário autorizado')
        } else {
          this.$toast.error(this.admRole.error);
        }

      }).catch(() => {
        this.$toast.error('Usuário não autorizado')
      }).finally(() => {
        this.admLogin.email = ''
        this.admLogin.password = ''
      })
    },
    validEmail() {
      if (this.admLogin.email === '') {
        this.message_error_email = 'Obrigatório';
        return false;
      }
      this.message_error_email = '';
      return true;
    },
    validPassword() {
      if (this.admLogin.password === '') {
        this.message_error_password = 'Obrigatório';
        return false;
      }
      this.message_error_password = '';
      return true;
    },
    loadCurrentRole() {
      this.getUser().then(response => {
        if (response.data.role.name == 'Admin') {
          this.admRole.authorization = true
        } else {
          this.admRole.authorization = false
        }
      })
    },
    loadObservations(id) {
      this.getAccommodationObservations(id).then(
        response => {
          this.accommodation_observations = response.data
        }
      )
    },
    addObservation() {
      let observation = {
        'accommodation_id': this.room.current_accommodation.id,
        'description': this.onservation_input
      }
      this.accommodation_observations.push(observation)

      const data = {
        'accommodation_id': this.room.current_accommodation.id,
        'observations': this.accommodation_observations
      }

      this.updateObservations(data).then(
        () => {
          this.$toast.success('Observação adicionada');
        }
      ).catch(
        () => {
          this.$toast.error('Error no servidor');
        }
      ).finally(
        () => {
          this.dialogObsCheckout = false
          this.onservation_input = ''
        }
      )
    },
    removeObservations(item) {
      const index = this.accommodation_observations.indexOf(item)
      this.accommodation_observations.splice(index, 1)

      const data = {
        'accommodation_id': this.room.current_accommodation.id,
        'observations': this.accommodation_observations
      }

      this.updateObservations(data).then(
        () => {
          this.$toast.success('Observação removida');
        }
      ).catch(
        () => {
          this.$toast.error('Error no servidor');
        }
      ).finally(
        () => {
          this.dialogObsCheckout = false
        }
      )
    },
    showDialogObsCheckout() {
      this.dialogObsCheckout = true
    },
    consumptionAlert() {
      this.alertConsumptionLimit(this.room.current_accommodation.id).then(response => {
        if (response.data.alert) {
          this.$swal({
            confirmButtonColor: '#16b1ff',
            position: "center",
            html: '<p>' + 'Hóspede já atingiu o limite de consumo.' + '</p>',
            icon: "warning",
            title: "Atenção",
          });
        }
      })
    },
    showExtraDialog() {
      this.accommodation_exta = this.room.current_accommodation.extra_items
      this.dialogExtra = true

    },
    addExtraItem() {
      this.accommodation_exta.push({ value: 0, description: '',type:'additional' })
    },
    addExtraItemDiscount() {
      this.accommodation_exta.push({ value: 0, description: '',type:'discount' })
    },
    removeExtraTariff(item) {
      const index = this.accommodation_exta.indexOf(item)
      this.accommodation_exta.splice(index, 1)
    },
    updateExtraTariff() {

      this.accommodation_exta = this.accommodation_exta.map(item => {
        if (item.type == 'additional') {
          return {
            'id': item.id,
            'value': item.value,
            'description': item.description,
            'type': item.type
          }
        } else {
          return {
            'id': item.id,
            'value': -Math.abs(item.value),
            'description': item.description,
            'type': item.type
          }
        }
      })

      const data = {
        'accommodation_id': this.room.current_accommodation.id,
        'extra_items': this.accommodation_exta
      }
      this.updateExtraTarrif(data).then(
        (response) => {
          this.$toast.success('Tarifas atualizadas');
          this.room.current_accommodation.extra_items = response.data
          this.storeCheckout()
        }
      ).catch(
        () => {
          this.$toast.error('Error no servidor');
        }
      ).finally(
        () => {
          this.dialogExtra = false
        }
      )
    },
    showOutstanding() {
      this.dialogOutstanding = true
    },
    moveOutstanding() {
      const payload = {
        room_category_id : this.room.room_category_id,
        room_id : this.room.id,
        description : this.room.description,
        status : 'open',
        prop: this.room.prop,
        current_accommodation_id: this.room.current_accommodation.id,
        current_reserve_id: this.room.current_reserve_id,
        status_time: new Date(),
      }

      this.storeOutstanding(payload).then(response => {
          this.dialogTranfRoom = false
          this.roomId = ''
          this.$emit('updategrid')
          this.$emit('close')
          this.$toast.success('Transferido com sucesso')
        })
        .finally(() => {
          this.loadingTransf = false
        })
    },
    showCancelDialog() {
      this.dialogCancel = true
    },
    sendCancelChekin() {
      //valida a justificativa
      if (this.cancelInput == '') {
        this.errorCancelInput = 'Campo Obrigatório'
        return false
      }
      this.loadingTransf = true
      
      const payload = {
        accommodation_id: this.room.current_accommodation.id,
        observation: this.cancelInput,
      }
      
      this.cancelCheckin(payload)
        .then(response => {
          this.dialogCancel = false
          this.roomId = ''
          this.$emit('updategrid')
          this.$emit('close')
          this.$toast.success('Checkin cancelado com sucesso')
        })
        .finally(() => {
          this.loadingTransf = false
        })
    },
    verifyReserveStatus(){
      const payload ={
        reserve_id: this.room.current_reserve_id,
        status: 'checkin'
      }
      this.updateReserve(payload)
    }
  }
}
</script>

<style scoped>

.custom-title {
  color: #FF4C51;
}
</style>
