<template>
<div>

    <v-row no-gutters>
        <v-col>
            <v-card outlined>
              <v-data-table height="210" :headers="headers" dense :items="guests" sort-by="calories" class="elevation-0" :footer-props="{
                  itemsPerPageText: 'Itens por pagina',
                  itemsPerPageOptions: [5],
                  showFirstLastPage: true,
              }">
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-toolbar-title>Hóspedes</v-toolbar-title>
                            <v-divider class="mx-4" inset vertical>
                            </v-divider>
                            <AutocompleteSearchGuest :errors="errors" v-model="guest" :dense="true" class="mt-6" />

                            <v-btn color="primary" dark class="mb-1 ml-4" @click="sendAddGuest()">
                                <v-icon small>
                                    {{ icons.mdiPlus }}
                                </v-icon>
                                adicionar
                            </v-btn>
                            <v-btn color="secondary" dark class="mb-1 ml-4" @click="showRegiterSimple()">
                                <v-icon >
                                    {{ icons.mdiPlus }}
                                </v-icon>
                                hóspede
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.actions="{ item }">
                        <v-icon color="error" @click="removeGuest(item)">
                            {{ icons.mdiDeleteOutline }}
                        </v-icon>
                    </template>
                    <template v-slot:no-data>
                        <div> Adicione o primeiro hóspede</div>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
    <DialogNewGuestVue v-model="dialogRegisterSimpleGuest" @result="resultRegisterGuest" />
</div>
</template>

<script>
import AutocompleteSearchGuest from '@/components/AutocompleteSearchGuest.vue'
import RegisterSimpleGuest from './RegisterSimpleGuest.vue'
import DialogNewGuestVue from './DialogNewGuest.vue'

import {
    mdiDeleteOutline,
    mdiPlus
} from '@mdi/js'
import {
    mapGetters,
    mapMutations
} from 'vuex'


export default {
    props: {
        value: {},
        errors:{}
    },
    components: {
        AutocompleteSearchGuest,
        RegisterSimpleGuest,
        DialogNewGuestVue
    },

    data: () => ({
        dialogRegisterSimpleGuest:false,
        guests: [],
        guest: '',
        headers: [{
                text: 'NOME',
                align: 'start',
                value: 'name',
            },
            {
                text: 'TELEFONE',
                value: 'cell_phone',
            },
            {
                text: 'EMAIL',
                value: 'email',
            },
            {
                text: '',
                align: 'end',
                value: 'actions',
            }
        ],
        icons: {
            mdiDeleteOutline,
            mdiPlus
        }

    }),
    watch: {
        value(val) {
          this.guests = val
        },
        guests(val){
          this.$emit('input',val)
        }
    },
    computed: {},
    methods: {
        sendAddGuest() {
            this.guests.push(this.guest)
            this.guest = ''
        },
        removeGuest(guest){
          const index = this.guests.indexOf(guest)
          this.guests.splice(index, 1)
        },
        showRegiterSimple(){
          this.dialogRegisterSimpleGuest = true
        },
        resultRegisterGuest(guest){
           this.guests.push(guest)
        }

    }
}
</script>

<style scoped>
nodata {
    height: 170px;
}
</style>
