<template>
  <v-dialog v-model="dialog" fullscreen persistent no-click-animation>
    <v-card>

      <BarTitleDialog :title="formTitle" @close="dialog = false" />
      <v-card-actions>
        <v-row>
          <v-col class="text-right">
            <v-btn color="blue darken-1" class="mr-4" x-large text @click="close">
              Fechar
            </v-btn>
            <v-btn :loading="loading" x-large color="primary" @click="save">
              Salvar
            </v-btn>
          </v-col>
        </v-row>

      </v-card-actions>
      <v-card-text>
        <v-container>
          <v-row no-gutters>
            <v-col cols="8">
              <v-text-field v-model="name" outlined label="Nome" :hide-details="true" :error="error_message.name !== ''"
                :error-messages="error_message.name" @input="error_message.name = ''"></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field class="ml-2" v-mask="'###.###.###-##'" v-model="editedItem.cpf" outlined label="CPF"
                :hide-details="true" :error="error_message.cpf !== ''" :error-messages="error_message.cpf"
                @input="error_message.cpf = ''"></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-checkbox v-model="editedItem.foreigner" class="ml-2" label="Estrangeiro" color="primary"
                hide-details></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-model="editedItem.incomplete_registration" class="ml-2" label="Cadastro Incompleto"
                color="primary" hide-details></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-model="editedItem.issue_nfc_e" label="Emite NFC-e" color="primary"
                hide-details></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-model="editedItem.issue_cf_e_sat" label="Emite CF-e-SAT" color="primary"
                hide-details></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox v-model="editedItem.inactive" label="Inativo" color="primary" hide-details></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card elevation="0" outlined>
                <v-tabs v-model="tab">
                  <v-tab>Endereço</v-tab>
                  <v-tab>Dados Pessoais</v-tab>
                  <v-tab>Familiares</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <!-- ENDEREÇO -->
                  <v-tab-item>
                    <v-card flat class="card-tab">
                      <v-card-text>
                        <v-row no-gutters>
                          <v-col>
                            <v-text-field v-model="editedItem.address" outlined hide-details label="Endereço"
                              :error="error_message.address !== ''" :error-messages="error_message.address"
                              @input="error_message.address = ''"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col cols="3">
                            <v-text-field v-model="editedItem.number" outlined hide-details label="Número"
                              :error="error_message.number !== ''" :error-messages="error_message.number"
                              @input="error_message.number = ''"></v-text-field>
                          </v-col>
                          <v-col class="ml-2">
                            <v-text-field v-model="editedItem.complement" outlined hide-details
                              label="Complemento"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col cols="12">
                            <v-text-field v-model="editedItem.neighborhood" outlined hide-details label="Bairro"
                              :error="error_message.neighborhood !== ''" :error-messages="error_message.neighborhood"
                              @input="error_message.neighborhood = ''"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col class="">
                            <v-text-field v-mask="'##.###-###'" v-model="editedItem.zipcode" outlined hide-details
                              label="CEP" @input="getCEP" :error="error_message.zipcode !== ''"
                              :error-messages="error_message.zipcode"></v-text-field>
                          </v-col>
                          <v-col cols="6" class="ml-2">
                            <v-select v-model="editedItem.country_id" :items="country_items" item-value="id"
                              item-text="name" outlined hide-details label="País"
                              :error="error_message.country_id !== ''" :error-messages="error_message.country_id"
                              @input="error_message.country_id = ''"></v-select>
                          </v-col>


                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col cols="">
                            <v-select @change="loadCities()" v-model="editedItem.state_id" :items="state_items"
                              item-value="id" item-text="name" outlined hide-details label="UF"
                              :error="error_message.state_id !== ''" :error-messages="error_message.state_id"
                              @input="error_message.state_id = ''"></v-select>
                          </v-col>
                          <v-col cols="" class="ml-2">
                            <v-select v-model="editedItem.city_id" :items="city_items" item-value="id" item-text="name"
                              outlined hide-details label="Cidade" :error="error_message.city_id !== ''"
                              :error-messages="error_message.city_id" @input="error_message.city_id = ''"></v-select>
                          </v-col>
                          <!-- <v-col class="ml-2">
                                                    <v-text-field v-model="editedItem.ibge_code" outlined hide-details label="Cod. IBGE"></v-text-field>
                                                </v-col> -->
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col cols="3">
                            <v-text-field v-mask="'(##)####-####'" v-model="editedItem.home_phone" outlined hide-details
                              label="Fone Res."></v-text-field>
                          </v-col>
                          <v-col cols="3" class="ml-2">
                            <v-text-field v-mask="'(##)####-####'" v-model="editedItem.commercial_phone" outlined
                              hide-details label="Fone Com."></v-text-field>
                          </v-col>
                          <v-col cols="3" class="ml-2">
                            <v-text-field v-mask="'(##)#####-####'" v-model="editedItem.cell_phone" outlined
                              hide-details label="Celular"></v-text-field>
                          </v-col>
                          <v-col class="ml-2">
                            <v-text-field v-mask="'(##)####-####'" v-model="editedItem.fax_phone" outlined hide-details
                              label="Fax"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col>
                            <v-text-field v-model="editedItem.inf_commercial" outlined hide-details
                              label="inf. Comerciais"></v-text-field>
                          </v-col>

                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col>
                            <v-text-field v-model="email" outlined hide-details label="Email"></v-text-field>
                          </v-col>
                        </v-row>

                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <!-- DADOS PESSOAIS -->
                  <v-tab-item>
                    <v-card flat class="card-tab">
                      <v-card-text>
                        <v-row no-gutters class="mt-2">
                          <v-col cols="6">
                            <v-text-field v-model="editedItem.cpf" outlined hide-details
                              label="Documento"></v-text-field>
                          </v-col>
                          <v-col cols="2" class="ml-2">
                            <v-text-field v-model="editedItem.type_doc" outlined hide-details
                              label="Tipo Doc"></v-text-field>
                          </v-col>
                          <v-col class="ml-2">
                            <v-text-field v-model="editedItem.org_exp" outlined hide-details
                              label="Org. Exp."></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col cols="4">
                            <!-- <InputDate :dense="false" :hide_details="true" label="Data Nascimento"  v-model="editedItem.date_of_birth" /> -->
                            <v-text-field type="date" v-model="editedItem.date_of_birth" outlined hide-details
                              label="Data Nascimento"></v-text-field>
                          </v-col>
                          <v-col cols="4" class="ml-2">
                            <v-select v-model="editedItem.gender" outlined hide-details label="Sexo"
                              :items="['Masculino', 'Feminino']"></v-select>
                          </v-col>
                          <v-col class="ml-2">
                            <v-select v-model="editedItem.marital_status"
                              :items="['Solteiro', 'Casado', 'Separado', 'Divorciado', 'Viúvo']" outlined hide-details
                              label="Estado Civil"></v-select>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col cols="6">
                            <v-text-field v-model="editedItem.naturalness" outlined hide-details
                              label="Naturalidade"></v-text-field>
                          </v-col>
                          <v-col class="ml-2">
                            <v-text-field v-model="editedItem.nationality" outlined hide-details
                              label="Nacionalidade"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col cols="6">
                            <v-text-field v-model="editedItem.father_name" outlined hide-details
                              label="Nome do Pai"></v-text-field>
                          </v-col>
                          <v-col class="ml-2">
                            <v-text-field v-model="editedItem.mother_name" outlined hide-details
                              label="Nome da Mãe"></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters class="mt-2">
                          <v-col cols="7">
                            <v-text-field v-model="editedItem.profession" outlined hide-details
                              label="Profissão"></v-text-field>
                          </v-col>
                          <v-col class="ml-2">
                            <v-text-field v-model="editedItem.origin" outlined hide-details
                              label="Origin"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <!-- fAMILIARES -->
                  <v-tab-item>
                    <v-card flat class="card-tab">
                      <v-card-text>
                        <Relatives />
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>

              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>
import Relatives from './Familiars.vue'

import { mdiClose, mdiDeleteOutline, mdiPencilOutline, mdiPlus } from '@mdi/js'

import { mapActions, mapGetters } from 'vuex'
import BarTitleDialog from '@/components/BarTitleDialog.vue'
import axios from "axios";

import InputDate from '@/components/InputDate.vue'
import index from 'vue-prism-component'
export default {
  components: {
    Relatives,
    BarTitleDialog,
    InputDate,
  },
  props: {
    value: {
      type: Boolean,
    },
    in_data: {
      type: Object,
    },
    index: {
      type: Number,
    },
  },
  data: () => ({
    dialogDelete: false,
    dialog: false,
    loading: false,
    country_items: [],
    state_items: [],
    city_items: [],
    error_message: {
      name: '',
      cpf: '',
      incomplete_registration: '',
      issue_nfc_e: '',
      issue_cf_e_sat: '',
      address: '',
      number: '',
      neighborhood: '',
      country_id: '',
      zipcode: '',
      state_id: '',
      city_id: '',
    },

    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      {
        text: 'PERFIL',
        value: 'name',
      },
      {
        text: '',
        align: 'end',
        value: 'actions',
        sortable: false,
      },
    ],
    familiars: [],
    editedItem: {
      name: '',
      cpf: '',
      incomplete_registration: '',
      issue_nfc_e: '',
      issue_cf_e_sat: '',
      address: '',
      number: '',
      neighborhood: '',
      country_id: '',
      zipcode: '',
      state_id: '',
      city_id: '',
      ibge_code: '',
      home_phone: '',
      commercial_phone: '',
      cell_phone: '',
      fax_phone: '',
      inf_commercial: '',
      email: '',
      doc: '',
      type_doc: '',
      org_exp: '',
      gender: '',
      marital_status: '',
      naturalness: '',
      nationality: '',
      father_name: '',
      mother_name: '',
      profession: '',
      origin: '',
    },
    defaultItem: {
      name: '',
      cpf: '',
      incomplete_registration: false,
      issue_nfc_e: false,
      issue_cf_e_sat: false,
      address: '',
      number: '',
      neighborhood: '',
      country_id: '',
      zipcode: '',
      state_id: '',
      city_id: '',
      ibge_code: '',
      home_phone: '',
      commercial_phone: '',
      cell_phone: '',
      fax_phone: '',
      inf_commercial: '',
      email: '',
      doc: '',
      type_doc: '',
      org_exp: '',
      gender: '',
      marital_status: '',
      naturalness: '',
      nationality: '',
      father_name: '',
      mother_name: '',
      profession: '',
      origin: '',
    },
    options: {},
    tab: null,
    items: [
      {
        tab: 'One',
        content: 'Tab 1 Content',
      },
      {
        tab: 'Two',
        content: 'Tab 2 Content',
      },
    ],
    icons: {
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiClose,
    },
  }),

  watch: {
    value(val) {
      this.dialog = val
      if (val) {
        this.inicialize()
      }
    },
    dialog(val) {
      this.$emit('input', val)
      if (!val) {
        this.close()
      }
    },
    in_data(val) {
      this.editedItem = {
        ...val,
      }
    },
  },

  created() { },

  computed: {
    ...mapGetters('guest', ['getFamiliars']),
    formTitle() {
      return 'Novo Hóspede'
    },
    name: {
      get() {
        return this.editedItem.name
      },
      set(val) {
        //letra maiusculas no inicio da palavra
        const vals = val.split(' ')
        val = vals
          .map(item => {
            if (item[0]) {
              return item[0].toUpperCase() + item.substring(1).toLowerCase()
            }
          })
          .join(' ')

        this.editedItem.name = val
      },
    },
    email: {
      get() {
        return this.editedItem.email
      },
      set(val) {
        this.editedItem.email = val.toLowerCase()
      },
    },
  },

  methods: {
    ...mapActions('guest', ['store', 'update']),
    ...mapActions('select', ['get_countries', 'get_states', 'get_cities']),
    inicialize() {
      this.get_countries().then(response => {
        this.country_items = response.data
      })

      this.get_states().then(response => {
        this.state_items = response.data
      })
    },
    loadCities() {
      const state = this.state_items.find(state => state.id == this.editedItem.state_id) // pega o objeto estado
      this.get_cities(state.code).then(response => {
        this.city_items = response.data
      })
    },
    loadCitiesViaCep(localidade) {
      const state = this.state_items.find(state => state.id == this.editedItem.state_id) // pega o objeto estado
      this.get_cities(state.code).then(response => {
        this.city_items = response.data
        let id_city = this.city_items.find(city => city.name == localidade)
        this.editedItem.city_id = id_city.id
      })

    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = {
          ...this.defaultItem,
        }
      })
    },
    save() {
      console.log(this.editedItem)  
      if (!this.validate()) {
        return
      }
      this.loading = true
      this.editedItem.familiars = this.getFamiliars
      console.log(this.editedItem)
      this.store(this.editedItem)
        .then(response => {
          const guest = response.data
          this.close()
          this.$toast.success('Salvo com sucesso !')
          this.$emit('result', guest)
        })
        .catch()
        .finally(() => {
          this.loading = false
        })
    },
    validate() {
      let valid = true
      if (this.editedItem.foreigner) {
        return true
      }
      if (this.editedItem.name == '') {
        this.error_message.name = 'Campo obrigatório'
        valid = false
      }
      if (this.editedItem.cpf == '') {
        this.error_message.cpf = 'Campo obrigatório'
        valid = false
      }
      if (this.editedItem.address == '') {
        this.error_message.address = 'Campo obrigatório'
        valid = false
      }
      if (this.editedItem.number == '') {
        this.error_message.number = 'Campo obrigatório'
        valid = false
      }
      if (this.editedItem.neighborhood == '') {
        this.error_message.neighborhood = 'Campo obrigatório'
        valid = false
      }
      if (this.editedItem.country_id == '') {
        this.error_message.country_id = 'Campo obrigatório'
        valid = false
      }
      if (this.editedItem.zipcode == '') {
        this.error_message.zipcode = 'Campo obrigatório'
        valid = false
      }
      if (this.editedItem.state_id == '') {
        this.error_message.state_id = 'Campo obrigatório'
        valid = false
      }
      if (this.editedItem.city_id == '') {
        this.error_message.city_id = 'Campo obrigatório'
        valid = false
      }

      return valid
    },

    getCEP() {

      if (this.editedItem.zipcode.length != 10) {
        return false
      }

      const cepValido = /^[0-9]{8}$/;


      let cep = this.editedItem.zipcode.replace(/\D/g, "");

      const response = axios.get(
        `https://viacep.com.br/ws/${cep}/json/`
      ).then(response => {
        const data = response.data
        this.editedItem.address = data.logradouro
        this.editedItem.neighborhood = data.bairro
        this.editedItem.country_id = 31
        let id_state = this.state_items.find(state => state.acronym == data.uf)
        this.editedItem.state_id = id_state.id
        this.loadCitiesViaCep(data.localidade)
      }).catch(error => {
        this.editedItem.address = ''
        this.editedItem.neighborhood = ""
        this.editedItem.country_id = ""
        this.editedItem.state_id = ""
        this.editedItem.city_id = ""

      });
    }

  },
}
</script>

<style scoped>
.card-tab {
  min-height: 420px;
  min-width: 736px;
}

.tool_table_fam {
  height: 50px;
}
</style>
